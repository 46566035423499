<script>

import { height } from "dom7";
import { SquareIcon } from "vue-feather-icons";


export default {
  components: {
    SquareIcon,
  },

  name: "LLaMA",
  data() {
    return {
      theses: [],
      article_strs: {
        0: '',
        1: '',
        2: '',
        3: '',
      },
      hash: '',
      // showSquares: {
      //   0: false,
      //   1: false,
      //   2: false,
      // },
      theses_str: '',
      thesisIndex: {
        0: 0,
        1: 0,
        2: 0,
        3: 0,
      },
      modelNames: ["GigaChat", "Vicuna 7B ft. by ДМиК", "3rd party", "llama2-saiga"],
      stopFlags: {
        0: true,
        1: true,
        2: true,
        3: true,
      },
      GIGACHAT_ID: 0,
      VICUNA_ID: 1,
      CHATGPT_ID: 2,
      SAIGA_ID: 3,

    }
  },
  watch: {
    theses_str: function (newVal, oldVal) {
      this.$refs.textarea.style.height = "auto";
      this.$refs.textarea.style.height = this.$refs.textarea.scrollHeight + 20 + 'px';
      this.theses = newVal.split("\n");
    },

  },
  mounted() {
    this.resize();

  },

  async created() {
    // prepare page
    this.hash = this.$CryptoJS.MD5("Hi There!" + new Date()).toString()
    console.log(' hash ' + this.hash)

    document.getElementById("theme-opt").setAttribute("href", "/css/style-null.css");
    document.getElementById("color-opt").setAttribute("href", "/css/colors/default-null.css");

    let params = new URLSearchParams(window.location.search)

    // run generation
    this.theses = JSON.parse(params.get('theses'));
    if (this.theses != null) {
      this.theses_str = this.theses.join("\n");
      this.runGenerateAll();

    }

  },
  methods: {
    async runGenerateAll() {
      console.log('launch runGenerate');
      this.runGenerateModel(this.CHATGPT_ID, 'cgpt');
      this.runGenerateModel(this.VICUNA_ID, 'vicu');
      this.runGenerateModel(this.GIGACHAT_ID, 'giga');
      this.runGenerateModel(this.SAIGA_ID, 'saiga');


    },

    height,
    resize() {
      const { textarea } = this.$refs;
      textarea.style.height = textarea.scrollHeight + 20 + 'px';
    },
    stopGenerate() {
      Object.keys(this.stopFlags).forEach(v => this.stopFlags[v] = true)
    },

    endGeneration(modelId) {
      // this.showSquares[modelId] = true;
      this.stopFlags[modelId] = true;

    },


    async runGenerateModel(modelId, modelName, startOver = true) {
      this.stopFlags[modelId] = false;
      // this.showSquares[modelId] = false;
      this.article_strs[modelId] = '';
      this.thesisIndex[modelId] = startOver ? 0 : this.thesisIndex[modelId]

      const evtSource = new EventSource(
        this.$hostname_ba + '/mediaplan/sse-request-' + modelName + '?hash_id=' + this.hash + "&abstracts=" + JSON.stringify(this.theses),
        {withCredentials:true}
        // 'http://localhost:8099' + '/sse-request-' + modelName + '?hash_id=' + this.hash + "&abstracts=" + JSON.stringify(this.theses)
      );

      let that = this;

      function updateEvent(event) {
        console.log(event);
        let message = event.data;
        console.log(" message: " + message + ' ')
        if (!that.stopFlags[modelId]) {
          that.article_strs[modelId] = that.article_strs[modelId] + message + '\n\n'
        }
      }
      function closeEvent(event) {
        console.log("Handling end of " + modelName);
        that.endGeneration(modelId);
        event.currentTarget.close()

      }

      evtSource.addEventListener("update_" + modelName, updateEvent);
      evtSource.addEventListener("end_" + modelName, closeEvent);

    },


  }
}
</script>




<template>
  <div>
    <div class="header">
      <a href="https://rmp-ift.sberbank.ru/mediaplan/communications" class="home">
        МЕДИАПЛАН
      </a>
      <div class="options">
        <a class="link" href="https://rmp-ift.sberbank.ru/mediaplan/communications">
          Главная
        </a>
        <a class="link" href="https://rmp-ift.sberbank.ru/sc/narrative-map">
          Карта нарративов
        </a>
        <a class="link" href="https://rmp-ift.sberbank.ru/sc/key-stories">
          Сюжеты
        </a>

        <a class="link" href="https://rmp-ift.sberbank.ru/mediaplan/communications">
          Личный кабинет
        </a>

        <a class="link speakers" href="https://rmp-ift.sberbank.ru/mediaplan/communications">Спикеры <svg
            xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 8 14">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
              d="m1 13 6-6-6-6"></path>
          </svg></a>

        <div class="search">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="none">
            <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"
              d="M9.167 15.833a6.667 6.667 0 1 0 0-13.333 6.667 6.667 0 0 0 0 13.333ZM17.5 17.5l-3.625-3.625" />
          </svg>
        </div>
      </div>
    </div>

    <div class="mainllama">
      <h1 class="title titleBold">Тезисы</h1>
      <!--<ul>
      <li v-for="thesis in  theses"><h4>{{thesis}}</h4></li>
    </ul> -->

      <div class="theses">
        <textarea @change="this.resize" ref="textarea" name="product" id="product"
          class="form-control pl-5 theses__textarea" placeholder="Введите тезисы (новый тезис - новая строка):"
          v-model="theses_str" />

        <div v-if="stopFlags[0]" class="flex-container flex-start">
          <button class="theses__btn theses__btn-submit" @click="runGenerateAll(true)">Начать заново </button>
          <!-- <button class="theses__btn theses__btn-submit" v-if="!showSquares[0]" @click="runGenerateAll(false)">Продолжить
            с
            текущего тезиса </button> -->
        </div>
        <div v-else class="flex-container flex-start">
          <button class="theses__btn theses__btn-stop" @click="stopGenerate">Остановить генерацию</button>
        </div>
        <p v-if="thesisIndex[0] > 0 & stopFlags[0]" class="flex-container flex-start">
          Текущий номер тезиса: {{ thesisIndex[0] }} (продолжение начнется с этого тезиса). Текст тезиса:
          {{ theses[thesisIndex[0]] }}
        </p>
      </div>

      <h1 class="title titleBold">AI-черновик текста (все дискурсы)</h1>
      <div>
        <b-tabs content-class="mt-3" justified v-model="tabIndex">
          <b-tab v-for="(n, index) in modelNames.length">

            <template #title>
              <b-spinner type="grow" small v-if="stopFlags[index] === false"></b-spinner>
              {{ modelNames[index] }}
            </template>
            <h5>{{ article_strs[index] }}</h5>
            <div class="fb-chat" v-if="stopFlags[index] === false">
              <div class="fb-chat__bubbles">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
            <!-- <square-icon v-if="showSquares[index]"></square-icon> -->
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<style scoped>
/* Offset between elements */
* {
  --container-width: 1366px;
  --offset-xxs: 4px;
  --offset-xs: 8px;
  --offset-sm: 12px;
  --offset-md: 16px;
  --offset-lg: 20px;
  --offset-xl: 24px;
  --offset-xxl: 28px;
  --offset-huge: 32px;
  --offset-jumbo: 40px;
  --color-light-gray: #97a0af;
  --color-light-bg-outlet: #e6e7f0;
  --color-light-bg-dark: #ebecf2;
  --color-light-black: #232628;
  --color-light-red: #ff8f73;
  --color-light-yellow: #ffe380;
  --color-light-divider-light: var(--color-text-light-gray);
  --color-light-bg-light: #f4f5f7;
  --color-light-bg-accent: #d3d8e7;
  --color-light-bg-accent-card: #ffffff51;
  --color-light-text-gray: #6b778c;
  --color-light-bg-gray: var(--color-light-bg-light);
  --size-xxs: 4px;
  --size-xs: 8px;
  --size-sm: 12px;
  --size-md: 16px;
  --size-lg: 20px;
  --size-xl: 24px;
  --size-xxl: 28px;
  --size-huge: 32px;
  --size-jumbo: 40px;
  --font-fallback: "Verdana", "Roboto", sans-serif;
  --font-base: "SB Sans Text", var(--font-fallback);
  --font-caps: "SB Sans UI Caps", var(--font-base);
  --font-12: 400 12px / 14px var(--font-base);
  --font-14: 400 14px / 18px var(--font-base);
  --font-14-medium: 500 14px / 18px var(--font-base);
  --font-14-semibold: 600 14px / 18px var(--font-base);
  --font-16: 400 16px / 20px var(--font-base);
  --font-16-caps: 400 16px / 20px var(--font-caps);
  --font-16-bold: 700 16px / 20px var(--font-base);
  --font-18: 400 18px / 23px var(--font-base);
  --font-18-medium: 600 18px / 23px var(--font-base);
  --font-20: 400 20px / 25px var(--font-base);
  --font-20-medium: 500 20px / 25px var(--font-base);
  --font-24-medium: 500 24px / 30px var(--font-base);
  --font-20-semibold: 600 20px / 25px var(--font-base);
  --font-28-medium: 500 28px / 35px var(--font-base);
  --font-28-bold: 600 28px / 35px var(--font-base);
  --font-32-semibold: 600 32px / 40px var(--font-base);
  /* Caps UI */
  --font-12-caps: 400 12px / 14px var(--font-caps);
  --font-20-caps: 400 20px / 28px var(--font-caps);
  --color-text-black: var(--color-light-black);
  --color-text-light-red: #ffd7de;
  --color-text-light-gray: #dfe1e6;
  --color-text-very-light-gray: #b1bac9;
  --color-text-very-dark-gray: #313845;
  --color-text-gray: var(--color-light-text-gray);
  --color-text-dark-white: #d9dde3;
  --color-text-light-yellow: #fff1ca;
  --color-primary: #7b56e2;
  --color-primary-dark: #7351d4;
  --color-interactive: #563599;
  --color-gray: #5c5c5c;
  --color-black: #2c2c2c;
  --color-gray-dark: #dadde3;
  --color-white: #fff;
  --color-red: #f00;
  --color-error: #f57a87;
  --color-success: #8dd9be;
  --color-danger: #ff5630;
}

.header {
  position: sticky;
  top: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: calc(var(--size-jumbo) + var(--size-xs));
  background-color: var(--color-black);
  padding: 0 var(--offset-jumbo);
}

.home {
  display: flex;
  align-items: center;
  height: 100%;
  padding: var(--offset-sm) 0;
  border: 0;
  border-radius: 0;
  font: var(--font-16-bold);
  color: var(--color-text-dark-white);
  text-decoration: none;
}

body {
  margin: 0;
}

.options {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.link {
  position: relative;
  padding: var(--offset-sm) var(--offset-xl);
  height: 100%;
  border: none;
  border-radius: 0;
  text-decoration: none;
  font: var(--font-14-medium);
  color: var(--color-text-dark-white);


}

.speakers {
  display: flex;
  gap: 8px;
  align-items: center;
}

.speakers svg {
  height: var(--size-xs);
  transform: rotate(90deg);
  transition: rotate .25s ease-in-out;
  width: var(--size-xs);
}


.link:has(+ a)::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  width: 1px;
  height: calc(100% - var(--offset-sm) * 2);
  background-color: var(--color-light-gray);
}

:where(button, a)[aria-disabled="true"],
button:disabled,
:global(.ant-btn-link:disabled) {
  pointer-events: none;
  background-color: transparent;
  color: var(--color-gray);

  &:global(.ant-btn:hover) {
    background-color: transparent;
    color: var(--color-gray);
  }
}

div.search {
  padding: 0;
  color: var(--color-text-dark-white);
  width: var(--size-lg);
  height: var(--size-lg);
  border: 0;
}



.link {
  display: flex;
  text-decoration: none;
  color: #D9DDE3;
}

.link:hover {
  color: #b1b5c5;
}

.textMedium {
  font: 500 14px / 18px 'SB Sans Text', 'Verdana', 'Roboto', sans-serif;
}

.titleBold {
  font: 600 32px / 40px 'SB Sans Text', 'Verdana', 'Roboto', sans-serif;
}

.mainllama {
  min-width: 390px;
  padding-top: 32px;
  padding-left: 40px;
  padding-right: 40px;
}

.title {
  margin: 0;
  padding: 0;
}

ul {
  margin-left: 24px;
  margin-top: 12px;
  line-height: 1.5rem;
}

h5 {
  width: 1000px;
  white-space: pre-wrap;
  line-height: 1.5em;
  font-size: 1.5em;
}


.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #000000;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #000000 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* Custom ui styles */

.theses {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 32px;
}

.theses__textarea {
  margin-bottom: 32px;
  resize: none;
  border-width: 2px;
  border-color: transparent;
  box-shadow: 0 0 0 1px #ebecf2;
  padding: 12px 18px !important;
}

.theses__textarea:focus {
  border-color: #7b56e2;
  transition: 0.3s ease-in-out;
  box-shadow: 0 4px 0 rgba(42, 5, 130, 0.08);
}

.theses__btn {
  font-weight: 500;
  margin: 0;
  outline: none;
  border: 0;
  cursor: pointer;
  padding: 11px 15px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7b56e2;
  color: #fff;
  margin: 5px;
  border-radius: 4px;
  transition: 0.3s ease-in-out;
  box-shadow: 0 4px 0 rgba(42, 5, 130, 0.08);
}

.theses__btn-stop {
  position: relative;
  padding-left: 28px;
  color: #232628;
  background-color: #f4f5f7;
  border: 1px solid #f4f5f7;
}

.theses__btn-stop::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 8px;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  background-color: #232628;
  z-index: 1;
  opacity: 0.25;
  transition: 0.3s ease-in-out;
}

.theses__btn-stop:active {
  color: #493a6a;
  border-color: #493a6a;
}

.theses__btn-stop:hover,
.theses__btn-stop:focus-visible {
  color: #7b56e2;
  border-color: #7b56e2;
}

.theses__btn-stop:hover::before,
.theses__btn-stop:focus-visible::before {
  background-color: #7b56e2;
  opacity: 0.9;
}

.theses__btn-submit:active {
  background-color: #493a6a;
}

.flex-container {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

.flex-start {
  justify-content: flex-start;
}

.fb-chat {
  width: 100px;
  line-height: 60px;
  display: block;
  border-radius: 30% / 50%;
  position: fixed;
  left: 50%;
  /*top: 50%;
  transform: translate(-50%, -50%);*/
}

.fb-chat__bubbles {
  text-align: center;
}

.fb-chat__bubbles span {
  display: inline-block;
  background-color: #B6B5BA;
  width: 7px;
  height: 7px;
  border-radius: 100%;
  margin-right: 5px;
  animation: bob 2s infinite;
}

.fb-chat__bubbles span:nth-child(1) {
  animation-delay: -1s;
}

.fb-chat__bubbles span:nth-child(2) {
  animation-delay: -0.85s;
}

.fb-chat__bubbles span:nth-child(3) {
  animation-delay: -0.7s;
  margin-right: 0;
}

@keyframes bob {
  10% {
    transform: translateY(-10px);
    background-color: #9E9DA2;
  }

  50% {
    transform: translateY(0);
    background-color: #B6B5BA;
  }
}
</style>